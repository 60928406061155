export const projectsData = [
    {
        id: 1,
        name: 'Caddie AI',
        description: "• Pioneered the creation of Caddie AI, merging Golf Swing theory Rag Systems with bespoke golf swing models by generating custom datasets & model architecture ranging from LSTMs,GANs, Fine-Tuned Llm, Swin Transformer & ML-Kit, Blender 3D models via threejs \n • Architected Backend with Python Flask,frontend with React and React Native,landing page in Nextjs.\n • Built relational DB models on sqlite along with Cloud Storage for user data storage.\n • Built the Ui Components in React that were also consumed in the react-native ios app via expo\n • Implemented full cycle cloud-ops using GCP ,supervisor,redis,pub/sub, expo-native,Docker\n • Conducted extensive research, grounding the tech with insights from 23 academic papers.\n • Achieved a breakthrough feature: live swing analysis, offering real-time voice feedback within 7 seconds by partitioning tasks between edge & cloud-based inference.\n • Managed Marketing Dashboards, Google / Meta / Appstore Ads\n • Grew the App to over 9,000 Instagram followers, 1,000+ app installs within 2 months of launch, 3 Golf influencers, current peak usage is 88 concurrent users",
        tools: ['Python', 'React', 'Flask', 'React-Native', 'GCP', 'Docker', 'Pub/Sub', 'Sql', 'NLP', 'Computer Vision', 'Nginx'],
        role: 'Fullstack Developer',
        code: 'https://apps.apple.com/us/app/caddie-ai-the-chatgpt-of-golf/id6455428257?platform=iphone',
        demo: 'https://app.cadddie.com',
        credentials: 'username: testing, password: 1234',
        image1: '/images/image1.jpg',
        image2: '/images/image2.jpg',
        video: 'https://demo.arcade.software/XcLvNNwJUCYG28rGoqRK?embed&show_copy_link=true',
    },
    {
        id: 2,
        name: 'Casebook AI',
        description: 'I have designed and developed a full-stack web app that leverages LLMs to provide in-depth legal analysis,contract + appeal document creation. I created the UI using React, Typescript, MUI, TailwindCSS, Quill-Editor, and a number of PDF libraries to create interactive highlights.The Backend is in Python flask, leveraging chromadb and openai amongst many other packages.',
        tools: ['React', 'Python', "TypeScript", "Embeddings", "LLMs", "Quill-JS", "Threejs","graph algorithms"],
        role: 'Full Stack Developer',
        code: 'https://github.com/yahyaghani/casebook_ai_frontend',
        demo: 'https://app.casebk.com',
        credentials: 'username: testing, password: 1234',

        image1: '/images/image1.jpg',
        image2: '/images/image2.jpg',
        video: 'https://demo.arcade.software/another-arcade-url?embed&show_copy_link=true',
    },
];
